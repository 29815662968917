var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bgcenters" }),
    _c(
      "div",
      [
        _c("headbox", {
          attrs: { title: "卖出结算", bgColor: "#ff2c30", iswhite: "true" }
        }),
        _vm.isLoading
          ? _c(
              "div",
              [
                _c(
                  "center",
                  { attrs: { id: "loadingbox" } },
                  [_c("van-loading", { attrs: { size: "68px" } })],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "centerboxs" },
              [
                _c("div", { staticClass: "topbg" }),
                _c(
                  "div",
                  { staticClass: "orderbox" },
                  [
                    _c(
                      "center",
                      [
                        +_vm.datalist.status == 2
                          ? _c("h1", { staticClass: "f32" }, [
                              _vm._v(" 请确认下方结算信息 ")
                            ])
                          : _vm._e(),
                        +_vm.datalist.status == 1
                          ? _c("h1", { staticClass: "f32" }, [
                              _vm._v(" 打款已完成，请查收 ")
                            ])
                          : _vm._e(),
                        +_vm.datalist.status == 0
                          ? _c("h1", { staticClass: "f32" }, [
                              _vm._v("您的款项正在飞速打款中....")
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "money" }, [
                          _c("b", { staticClass: "f32" }, [_vm._v("￥")]),
                          _vm._v(" " + _vm._s(_vm.datalist.moneyTotal) + " ")
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "btnsee f32",
                            on: { click: _vm.dianzidanju }
                          },
                          [_vm._v("查看详情")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "topcenter" }, [
                  _c("div", { staticClass: "toptitle" }, [_vm._v("物料明细")]),
                  _c("div", [
                    _c("div", { key: 0, staticClass: "flex-between" }, [
                      _c("div", [_vm._v("来料重量")]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.datalist.weightLaiLiaoTotal) + "克")
                      ])
                    ]),
                    _c("div", { key: 1, staticClass: "flex-between" }, [
                      _c("div", [_vm._v("折足总重")]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.datalist.weightLaiLiaoZeZuTotal) + "克"
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "toptitle mart" }, [
                    _vm._v("结算明细")
                  ]),
                  _c("div", { staticClass: "sectionbox" }, [
                    _c("div", { staticClass: "flex-betweens" }, [
                      _c("div", [_vm._v("合计金额：")]),
                      _c("div", { staticClass: "blackFont" }, [
                        _vm._v(_vm._s(_vm.datalist.moneyTotal) + "元")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "toptitle mart" }, [
                    _vm._v("合计金额")
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("提纯费：")]),
                      _c("div", { staticClass: "blackFont" }, [
                        _vm._v(_vm._s(_vm.datalist.moneyTiChun) + "元")
                      ])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("快递费：")]),
                      _c("div", { staticClass: "blackFont" }, [
                        _vm._v(_vm._s(_vm.datalist.moneyYunFei) + "元")
                      ])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("保险费：")]),
                      _c("div", { staticClass: "blackFont" }, [
                        _vm._v(_vm._s(_vm.datalist.moneyBaoXiang) + "元")
                      ])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("保管费：")]),
                      _c("div", { staticClass: "blackFont" }, [
                        _vm._v(_vm._s(_vm.datalist.moneyStorageFee) + "元")
                      ])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("合计费用：")]),
                      _c("div", { staticClass: "countyellow" }, [
                        _vm._v(" " + _vm._s(_vm.datalist.moneyTotal) + "元 ")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "toptitle mart " }, [
                    _vm._v("订单信息")
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("顺丰单号：")]),
                      _c("div", [_vm._v(_vm._s(_vm.datalist.orderNoExpress))])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("结算单号：")]),
                      _c("div", [_vm._v(_vm._s(_vm.datalist.orderNo))])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("开单时间：")]),
                      _c("div", [_vm._v(_vm._s(_vm.datalist.date))])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("用户姓名：")]),
                      _c("div", [_vm._v(_vm._s(_vm.datalist.name))])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("联系电话：")]),
                      _c("div", [_vm._v(_vm._s(_vm.datalist.phone))])
                    ]),
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", [_vm._v("收款账号：")]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.datalist.bankName + _vm.datalist.bankNum)
                        )
                      ])
                    ])
                  ])
                ]),
                +_vm.datalist.status == 2
                  ? _c(
                      "center",
                      { staticClass: "rel" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_red onlybox",
                            on: { click: _vm.havapro }
                          },
                          [_vm._v("对结算单有异议？")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_red onlyboxsecond_yellow",
                            on: { click: _vm.postForm }
                          },
                          [_vm._v("确认无误")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                +_vm.datalist.status == 0
                  ? _c(
                      "center",
                      { staticClass: "rel" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_red onlybox",
                            on: { click: _vm.havapro }
                          },
                          [_vm._v("对结算单有异议？")]
                        ),
                        _c(
                          "el-button",
                          { staticClass: "btn_red overconfirm onlyboxsecond" },
                          [_vm._v("已确认")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                +_vm.datalist.status == 1
                  ? _c(
                      "center",
                      { staticClass: "rel" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_red",
                            staticStyle: { width: "90%" },
                            on: { click: _vm.addover }
                          },
                          [_vm._v("再来一单")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "van-dialog",
          {
            attrs: { showConfirmButton: false },
            model: {
              value: _vm.popupbox,
              callback: function($$v) {
                _vm.popupbox = $$v
              },
              expression: "popupbox"
            }
          },
          [
            _c("div", { staticClass: "titlebox_popup" }, [_vm._v("提示")]),
            _c("div", { staticClass: "detailsbox" }, [
              _vm._v(
                " 如结算单有误需拨打" +
                  _vm._s(_vm.GLOBAL) +
                  "客服人工核对修改，是否拨打" +
                  _vm._s(_vm.GLOBAL) +
                  "客服电话：400-850-6718 "
              )
            ]),
            _c("div", { staticClass: "flex btn-flex rel" }, [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.popupbox = !_vm.popupbox
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "a",
                {
                  staticClass: "redF",
                  attrs: { href: "tel:" + "400-850-6718" }
                },
                [_vm._v("确认")]
              )
            ])
          ]
        ),
        _c(
          "van-dialog",
          {
            attrs: { showConfirmButton: false },
            model: {
              value: _vm.iscomfirm,
              callback: function($$v) {
                _vm.iscomfirm = $$v
              },
              expression: "iscomfirm"
            }
          },
          [
            _c("div", { staticClass: "titlebox_popup" }, [_vm._v("结算确认")]),
            _c("div", { staticClass: "detailsbox" }, [
              _vm._v(
                " 尊敬的用户，您正在进行结算确认，请仔细核对您的来料重量与结算价格。确认无误后，" +
                  _vm._s(_vm.GLOBAL) +
                  "将在一个工作日内完成打款。 "
              )
            ]),
            _c("div", { staticClass: "flex btn-flex rel" }, [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.iscomfirm = !_vm.iscomfirm
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("div", { staticClass: "redF", on: { click: _vm.querenbox } }, [
                _vm._v("确认")
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }